import { map, startWith } from "rxjs/operators";
import {
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";
import { Component, forwardRef, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";

//3rd party deps
import {
  MDBModalRef,
  MDBModalService,
  MdbSelectComponent,
  PopoverDirective,
} from "ng-uikit-pro-standard";

//internal services
import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { Observable, Subject } from "rxjs";

import {
  faPlusSquare,
  faMinus,
  faTimes,
  faMagnifyingGlass,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { DOCUMENT, TitleCasePipe } from "@angular/common";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { NgxCSVParserError, NgxCsvParser } from "ngx-csv-parser";

@Component({
  selector: "app-manage-phoneme-lists",
  templateUrl: "./manage-phoneme-lists.component.html",
  styleUrls: ["./manage-phoneme-lists.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MdbSelectComponent),
      multi: true,
    },
    TitleCasePipe
  ],
})
export class ManagePhonemeListsComponent implements OnInit {
  selectControl = new UntypedFormControl("1");

  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  @ViewChild('fileImportInput') fileImportInput: any;

  //icons
  public faPlusSquare = faPlusSquare;
  public faMinus = faMinus;
  public faTimes = faTimes;
  public faMagnifyingGlass = faMagnifyingGlass;
  public faCirclePlus = faCirclePlus;

  options = [
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" },
  ];

  //incoming
  public clientSettings: any;
  public skillID: number;
  public action: string;
  public targetList: any;
  public phonemeTypes: any;
  public unpredictableVowelTeams: any;
  public loadingNotification: any;

  //loading
  public formLoading: boolean = true;

  //persistent
  public token: string;
  public notification: any;
  public formState: string = "pending";
  public groupType: string = "none";
  public isClean: boolean = true;

  //csv
  public csvRecords: any[] = [];

  //copy
  public formTitle: string = "Add Word List";
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
      close: "Close",
      main: "Add Word List",
      reset: "Add More Words",
      retry: "Retry",
    };

  //form related
  public managePhonemesForm: UntypedFormGroup;
  public wordListForm: UntypedFormGroup;

  //data back to parent
  private outgoing: Subject<any> = new Subject();
  constructor(
    public manageWordsFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService,
    private ngxCsvParser: NgxCsvParser,
    private TitleCase: TitleCasePipe,
  ) { }

  ngOnInit(): void {

    console.log("targetList in manage-phoneme-lists", this.targetList);
    console.log("action in manage-phoneme-lists", this.action);

    this.retrieveToken();

    this.formTitle = `${this.TitleCase.transform(this.action)} Word List`;

    if (this.action === "update") {
      this.btnLabel.main = "Update Word List";
    }

    //instantiate forms
    this.wordListForm = new UntypedFormGroup({
      list_name: new UntypedFormControl(this.targetList ? this.targetList.label : "", Validators.required),
      list_description: new UntypedFormControl(this.targetList ? this.targetList.description : ""),
    });

    this.managePhonemesForm = new UntypedFormGroup({
      words: new UntypedFormArray([]),
    });

    this.addPhonemeFields();
    console.log("this.phoneTypes in manage-phoneme-lists", this.phonemeTypes);
    console.log("this.unpredictableVowelTeams in manage-phoneme-lists", this.unpredictableVowelTeams);

  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public fileChangeListener($event: any): void {
    console.log("fileChangeListener", $event);

    let notification = this._notificationService.savingNotification("Parsing CSV file");

    const files = $event.srcElement.files;
    this.ngxCsvParser
      .parse(files[0], {
        header: true,
        delimiter: ',',
        encoding: 'utf8'
      })
      .pipe()
      .subscribe(
        (result: Array<any>) => {
          console.log('Result', result);
          this._notificationService.clearNotification(notification);
          this._notificationService.successNotification("CSV file parsed successfully, please review the word entries and then click the 'Add Word List' button", 5000);

          console.log("managePhonemeFieldsGroups in fileChangeListener", this.managePhonemeFieldsGroups);

          //remove any existing form fields that are empty
          let emptyFields = this.managePhonemeFieldsGroups.filter((field) => {
            return field.value.full_word === "";
          });

          if (emptyFields.length > 0) {
            emptyFields.forEach((field) => {
              let index = this.managePhonemesFormArray.controls.indexOf(field);
              this.managePhonemesFormArray.removeAt(index);
            });
          }

          if (Array.isArray(result) && result.length > 0) {

            result.forEach((word) => {

              let thisWord = {
                word: word.shift(),
                phoneme_list: []
              }
              let phonemes = [];

              const chunkSize = 5;

              //create a new object where the first key is the word and the rest of the keys are the phonemes, put into an array
              //each phoneme has the following keys: phoneme_{index}, phoneme_type_{index}, phoneme_spelling_{index}, less_common_sound_{index}, has_silent_e_{index}
              //where index is the index of the phoneme in the word
              for (let i = 0; i < word.length; i += chunkSize) {
                const chunk = word.slice(i, i + chunkSize);

                //if chunk size is too small, skip
                if (chunk.length < chunkSize) continue;

                const symbol = chunk[0].trim();
                const type = chunk[1].trim();
                const spelling = chunk[2].trim();
                const is_silent_e = chunk[4].trim();
                const is_uncommon = chunk[3].trim(); // Store the value at index 3

                const obj = {
                  symbol: symbol,
                  type: this.convertCSVTypetoPhonemeType(type, spelling, is_uncommon),
                  spelling: spelling,
                  is_silent_e: is_silent_e === "Yes" ? true : false,
                };

                phonemes.push(obj);
              }

              if (thisWord.word === "foal") console.log("phonemes in fileChangeListener", phonemes);

              thisWord.phoneme_list = phonemes;

              if (thisWord.word === "foal") console.log("thisWord in fileChangeListener", thisWord);

              this.addPhonemeWord(thisWord);

            });

            //trigger form validation
            this.isClean = false;
            this.managePhonemesForm.markAllAsTouched();

          }
        },
        (error: NgxCSVParserError) => {
          console.log('Error', error);
          this._notificationService.errorNotification("Error parsing CSV file, please check the file and try again");
        }
      );
  }

  public convertCSVTypetoPhonemeType(csvType, spelling, less_common_sound) {
    let phonemeType = "consonant";

    if (csvType === "Vowel team") {

      phonemeType = "vowel_team";

      let checkUnpredictable = this.unpredictableVowelTeams.filter((team) => {
        return spelling === team.name;
      });

      if (checkUnpredictable.length > 0) {

        if (less_common_sound === "Yes") {
          phonemeType = "unpredictable_vowel_teams_uncommon";
        } else {
          phonemeType = "unpredictable_vowel_teams_common";
        }

      } else {

        //check or, er, and ar vowel teams
        if (spelling === "or") {

          phonemeType = "vowel_r_or";

        } else if (spelling === "er") {

          phonemeType = "vowel_r_er";

        } else if (spelling === "ar") {

          phonemeType = "vowel_r_ar";

        }
      }
    } else {

      phonemeType = "consonant";

      let findTypeValue = this.phonemeTypes.filter((type) => {
        return type.matching_label === csvType;
      });

      if (findTypeValue.length > 0) {
        phonemeType = findTypeValue[0].internal_type;
      }

    }

    return phonemeType;

  }

  get list_name() {
    return this.wordListForm.get("list_name");
  }

  get managePhonemesFormControls() {
    return this.managePhonemesForm.controls;
  }
  get managePhonemesFormArray() {
    return this.managePhonemesFormControls.words as UntypedFormArray;
  }

  get managePhonemeFieldsGroups() {
    return this.managePhonemesFormArray.controls as UntypedFormGroup[];
  }

  public addPhonemeFields() {

    if (this.action === "add") {
      this.phonemeFieldStart();
    } else if (this.action === "update") {
      this.targetList.phoneme_words.forEach((word) => {

        word.phoneme_list = word.phonemes;
        console.log("word in addPhonemeFields", word);

        this.addPhonemeWord(word);
      });

      //trigger form validation
      this.isClean = false;
      this.managePhonemesForm.markAllAsTouched();

      this._notificationService.clearNotification(this.loadingNotification);
    }

    this.formLoading = false;
  }

  public addPhonemeWord(word) {

    let thisValue = {
      full_word: word.word,
    }

    word.phoneme_list.forEach((phoneme, index) => {
      thisValue[`phoneme_${index + 1}`] = phoneme.symbol;
      thisValue[`phoneme_type_${index + 1}`] = phoneme.type;
      thisValue[`phoneme_spelling_${index + 1}`] = phoneme.spelling;
      thisValue[`less_common_sound_${index + 1}`] = phoneme.type === "unpredictable_vowel_teams_uncommon" ? "true" : "false";
      thisValue[`has_silent_e_${index + 1}`] = phoneme.is_silent_e;
    });

    this.phonemeFieldStart(thisValue);
  }

  public phonemeFieldStart(values?) {
    let defaultValues = {
      full_word: "",
      phoneme_1: "",
      phoneme_type_1: "",
      phoneme_spelling_1: "",
      less_common_sound_1: false,
      has_silent_e_1: false,
      phoneme_2: "",
      phoneme_type_2: "",
      phoneme_spelling_2: "",
      less_common_sound_2: false,
      has_silent_e_2: false,
      phoneme_3: "",
      phoneme_type_3: "",
      phoneme_spelling_3: "",
      less_common_sound_3: false,
      has_silent_e_3: false,
      phoneme_4: "",
      phoneme_type_4: "",
      phoneme_spelling_4: "",
      less_common_sound_4: false,
      has_silent_e_4: false,
      phoneme_5: "",
      phoneme_type_5: "",
      phoneme_spelling_5: "",
      less_common_sound_5: false,
      has_silent_e_5: false,
    };

    if (values === undefined) {
      values = defaultValues;
    } else {
      values = { ...defaultValues, ...values };
    }

    /**
     * New form array with following fields, none are currently required
     *- full_word: text
      - phoneme_1: text
      - phoneme_type_1: text
      - phoneme_spelling_1: text
      - less_common_sound_1: text
      - has_silent_e_1: text
      - phoneme_2: text
      - phoneme_type_2: text
      - phoneme_spelling_2: text
      - less_common_sound_2: text
      - has_silent_e_2: text
      - phoneme_3: text
      - phoneme_type_3: text
      - phoneme_spelling_3: text
      - less_common_sound_3: text
      - has_silent_e_3: text
      - phoneme_4: text
      - phoneme_type_4: text
      - phoneme_spelling_4: text
      - less_common_sound_4: text
      - has_silent_e_4: text
      - phoneme_5: text
      - phoneme_type_5: text
      - phoneme_spelling_5: text
      - less_common_sound_5: text
      - has_silent_e_5: text
      - id: number
     * 
     */

    let newFormArray = new UntypedFormGroup({
      full_word: new UntypedFormControl(values.full_word, Validators.required),
      phoneme_1: new UntypedFormControl(values.phoneme_1),
      phoneme_type_1: new UntypedFormControl(values.phoneme_type_1),
      phoneme_spelling_1: new UntypedFormControl(values.phoneme_spelling_1),
      less_common_sound_1: new UntypedFormControl(values.less_common_sound_1),
      has_silent_e_1: new UntypedFormControl(values.has_silent_e_1),
      phoneme_2: new UntypedFormControl(values.phoneme_2),
      phoneme_type_2: new UntypedFormControl(values.phoneme_type_2),
      phoneme_spelling_2: new UntypedFormControl(values.phoneme_spelling_2),
      less_common_sound_2: new UntypedFormControl(values.less_common_sound_2),
      has_silent_e_2: new UntypedFormControl(values.has_silent_e_2),
      phoneme_3: new UntypedFormControl(values.phoneme_3),
      phoneme_type_3: new UntypedFormControl(values.phoneme_type_3),
      phoneme_spelling_3: new UntypedFormControl(values.phoneme_spelling_3),
      less_common_sound_3: new UntypedFormControl(values.less_common_sound_3),
      has_silent_e_3: new UntypedFormControl(values.has_silent_e_3),
      phoneme_4: new UntypedFormControl(values.phoneme_4),
      phoneme_type_4: new UntypedFormControl(values.phoneme_type_4),
      phoneme_spelling_4: new UntypedFormControl(values.phoneme_spelling_4),
      less_common_sound_4: new UntypedFormControl(values.less_common_sound_4),
      has_silent_e_4: new UntypedFormControl(values.has_silent_e_4),
      phoneme_5: new UntypedFormControl(values.phoneme_5),
      phoneme_type_5: new UntypedFormControl(values.phoneme_type_5),
      phoneme_spelling_5: new UntypedFormControl(values.phoneme_spelling_5),
      less_common_sound_5: new UntypedFormControl(values.less_common_sound_5),
      has_silent_e_5: new UntypedFormControl(values.has_silent_e_5),
      id: new UntypedFormControl(this.targetList ? this.targetList.id : null),
    });

    newFormArray.valueChanges.subscribe((value) => {
      this.isClean = false;
    });

    this.managePhonemesFormArray.push(newFormArray);

  }

  public removePhonemeField(index) {
    this.managePhonemesFormArray.removeAt(index);
  }

  public async addWordList() {
    this.formState = "processing";
    this.notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(this.action)}ing Words`
    );
    let formValues = this.managePhonemesForm.value;
    let listValues = this.wordListForm.value;
    console.log("formValues in addWordList", formValues);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };
    const options = {
      headers: headers,
    };

    let body = {
      "label": listValues.list_name,
      "description": listValues.list_description,
      "original_filename": "tbd",
      "words": formValues.words
    }

    console.log("body in addWordList", body);

    let submitWords = {
      phoneme_list: []
    }

    if (this.action === "add"){
      submitWords = await this.submitWordList(body, options);
    } else {
      submitWords = await this.updateWordList(body, options, this.targetList.id);
    }

    let outgoingData = {
      phoneme_list: submitWords.phoneme_list,
    };
    let errors = false;

    this._notificationService.clearNotification(this.notification);

    this.finalActions(outgoingData, errors);
  }

  private async submitWordList(body, options) {
    const url = `/authoring/skill/${this.skillID}/phoneme-list`;
    return this._xrPlatformRestService.restfulAPIQuery(url, "post", body, options).toPromise();
  }

  private async updateWordList(body, options, list_id) {
    const url = `/authoring/skill/${this.skillID}/phoneme-list/${list_id}`;
    return this._xrPlatformRestService.restfulAPIQuery(url, "put", body, options).toPromise();
  }

  private finalActions(data, errors) {
    if (!errors) {
      this._notificationService.successNotification(
        `All words ${this.action}ed successfully`
      );
    } else {
      this._notificationService.errorNotification(
        `Some words could not be ${this.action}ed`
      );
    }

    this.outgoing.next({
      data: data,
      action: this.action,
    });

    this.formState = "complete";
    this.closeModal(true);
  }

  public showingWarning() {
    this.managePhonemesForm.markAllAsTouched();
  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    if (!this.isClean && !overrule) return false;

    this.manageWordsFrame.hide();
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }
}