import { map, startWith } from "rxjs/operators";
import {
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";
import { Component, forwardRef, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";

//3rd party deps
import {
  MDBModalRef,
  MDBModalService,
  MdbSelectComponent,
  PopoverDirective,
} from "ng-uikit-pro-standard";

//internal services
import { UserServicesService } from "src/app/modules/user-management/services/user-services.service";
import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { Observable, Subject } from "rxjs";

import {
  faPlusSquare,
  faMinus,
  faTimes,
  faMagnifyingGlass,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { DOCUMENT, TitleCasePipe } from "@angular/common";
import { ManageUserGroupRosterComponent } from "../../../user-groups/modals/manage-user-group-roster/manage-user-group-roster.component";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { EditUserComponent } from "../edit-user/edit-user.component";
import { NgxCSVParserError, NgxCsvParser } from "ngx-csv-parser";

@Component({
  selector: "app-manage-system-users",
  templateUrl: "./manage-system-users.component.html",
  styleUrls: ["./manage-system-users.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MdbSelectComponent),
      multi: true,
    },
    TitleCasePipe
  ],
})
export class ManageSystemUsersComponent implements OnInit {
  selectControl = new UntypedFormControl("1");

  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  @ViewChild('fileImportInput') fileImportInput: any;

  //icons
  public faPlusSquare = faPlusSquare;
  public faMinus = faMinus;
  public faTimes = faTimes;
  public faMagnifyingGlass = faMagnifyingGlass;
  public faCirclePlus = faCirclePlus;

  options = [
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" },
  ];
  //incoming
  public clientSettings: any;
  public teamID: number;
  public labels: any;
  public expPersonaSelect: { value: number; label: string }[];

  //group related
  searchText = new Subject();
  results: Observable<{ name: string }[]>;
  public userGroupForm: any;
  public userGroupSelect: any = [];

  //loading
  public formLoading: boolean = true;

  //persistent
  public token: string;
  public showPersona: boolean = false;
  public notification: any;
  public formState: string = "pending";
  public groupType: string = "none";
  public isClean: boolean = true;

  //csv
  public csvRecords: any[] = [];

  //copy
  public formTitle: string = "Add User";
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
      close: "Close",
      main: "Add Users",
      reset: "Add More Users",
      retry: "Retry",
    };

  //form related
  public manageSystemUsersForm: UntypedFormGroup;
  public adminCaps: { value: number; label: string; name: string; }[];
  public expRoleSelect: { value: number; label: string; name: string; }[];

  //for modals
  //modals
  public manageGroupRosterFrame: MDBModalRef;
  public restoreFrame: MDBModalRef;
  private modalOptions = {
    backdrop: "static",
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  constructor(
    public manageSystemUsersFrame: MDBModalRef,
    private _userService: UserServicesService,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private modalService: MDBModalService,
    private _notificationService: NotificationsService,
    private TitleCase: TitleCasePipe,
    private ngxCsvParser: NgxCsvParser,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.retrieveToken();

    this.showPersona = this.clientSettings.showPersona.unconstrained_default;

    this.adminCaps = this.formatAdminCaps(
      this.clientSettings.adminCaps.options
    );
    this.expRoleSelect = this.formatRoleSelect(
      this.clientSettings.expRoleSelect.options
    );

    console.log("this.expRoleSelect", this.expRoleSelect);

    //instantiate form
    this.manageSystemUsersForm = new UntypedFormGroup({
      users: new UntypedFormArray([]),
    });

    this.retrieveData();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private filter(option): string[] {
    return this.userGroupSelect.filter((group) =>
      group.name
        .toLowerCase()
        .includes(
          option.name ? option.name.toLowerCase() : option.toLowerCase()
        )
    );
  }

  public onDisplayValue(group): string | undefined {
    return group ? group.name : "";
  }

  private async retrieveData() {
    this.userGroupForm = new UntypedFormGroup({
      group_type: new UntypedFormControl("none"),
      userGroup: new UntypedFormControl(""),
      group_name: new UntypedFormControl(""),
      group_description: new UntypedFormControl(""),
    });

    this.userGroupForm.get("userGroup").valueChanges.subscribe((value) => {
      this.isClean = false;
    });

    this.userGroupForm.get("group_name").valueChanges.subscribe((value) => {
      this.isClean = false;
    });

    this.userGroupForm
      .get("group_description")
      .valueChanges.subscribe((value) => {
        this.isClean = false;
      });

    this.userGroupForm.get("group_type").valueChanges.subscribe((value) => {
      this.groupType = value;

      //set or clear validators based on group type selection
      if (value === "none") {
        this.userGroupForm.get("userGroup").clearValidators();
        this.userGroupForm.get("userGroup").updateValueAndValidity();
        this.userGroupForm.get("group_name").clearValidators();
        this.userGroupForm.get("group_name").updateValueAndValidity();
      } else if (value === "existing") {
        this.userGroupForm.get("userGroup").setValidators(Validators.required);
        this.userGroupForm.get("userGroup").updateValueAndValidity();
        this.userGroupForm.get("group_name").clearValidators();
        this.userGroupForm.get("group_name").updateValueAndValidity();
      } else if (value === "new") {
        this.userGroupForm.get("userGroup").clearValidators();
        this.userGroupForm.get("userGroup").updateValueAndValidity();
        this.userGroupForm.get("group_name").setValidators(Validators.required);
        this.userGroupForm.get("group_name").updateValueAndValidity();
      }

      //examine validators for userGroupForm
      console.log(
        "userGroupForm validators",
        this.userGroupForm.get("userGroup").validator,
        this.userGroupForm.get("group_name").validator
      );
    });

    let groupList = await this.getGroupList();

    this.userGroupSelect = groupList.group_information;

    this.results = this.searchText.pipe(
      startWith(""),
      map((group) => (group ? this.filter(group) : this.userGroupSelect))
    );

    this.addUserFields();
  }

  getGroupList() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let getGroup = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/groups",
      "get",
      {},
      options
    );

    return getGroup.toPromise();
  }

  private formatAdminCaps(caps) {
    let adminCaps = [];
    for (let key in caps) {
      if (caps[key].is_default) {
        adminCaps.push({
          label: this._userService.systemCapabilityLabelling(
            caps[key].label,
            +caps[key].value
          ),
          value: +caps[key].value,
          name: caps[key].name,
        });
      }
    }

    return adminCaps;
  }

  private formatRoleSelect(roles) {
    let roleSelect = [];
    for (let key in roles) {
      if (roles[key].is_default) {
        roleSelect.push({ label: roles[key].label, value: +roles[key].value, name: roles[key].name });
      }
    }

    return roleSelect;
  }

  public fileChangeListener($event: any): void {
    console.log("fileChangeListener", $event);

    let notification = this._notificationService.savingNotification("Parsing CSV file");

    const files = $event.srcElement.files;
    this.ngxCsvParser
      .parse(files[0], {
        header: true,
        delimiter: ',',
        encoding: 'utf8'
      })
      .pipe()
      .subscribe(
        (result: Array<any>) => {
          console.log('Result', result);
          this._notificationService.clearNotification(notification);
          this._notificationService.successNotification("CSV file parsed successfully, please review the user entries and then click the 'Add Users' button", 5000);

          //remove any existing form fields that are empty
          let emptyFields = this.systemUserFieldsGroups.filter((field) => {
            return field.value.first_name === "" && field.value.last_name === "" && field.value.username === "" && field.value.email === "";
          });

          if (emptyFields.length > 0) {
            emptyFields.forEach((field) => {
              let index = this.systemUsersFormArray.controls.indexOf(field);
              this.systemUsersFormArray.removeAt(index);
            });
          }

          if (Array.isArray(result) && result.length > 0) {

            result.forEach((entry) => {
              this.addUserFields(entry);
            });

          }

          //trigger form validation
          this.isClean = false;
          this.manageSystemUsersForm.markAllAsTouched();

        },
        (error: NgxCSVParserError) => {
          console.log('Error', error);
          this._notificationService.errorNotification("Error parsing CSV file, please check the file and try again");
        }
      );
  }

  get userGroup() {
    return this.userGroupForm.get("userGroup");
  }

  get group_name() {
    return this.userGroupForm.get("group_name");
  }

  get manageSystemUsersFormControls() {
    return this.manageSystemUsersForm.controls;
  }
  get systemUsersFormArray() {
    return this.manageSystemUsersFormControls.users as UntypedFormArray;
  }

  get systemUserFieldsGroups() {
    return this.systemUsersFormArray.controls as UntypedFormGroup[];
  }

  public addUserFields(values?) {

    if (values === undefined) values = null;

    let newFormArray = new UntypedFormGroup({
      first_name: new UntypedFormControl(values ? values.first_name : "", Validators.required),
      last_name: new UntypedFormControl(values ? values.last_name : "", Validators.required),
      username: new UntypedFormControl(values ? values.username : "", Validators.required),
      email: new UntypedFormControl(
        values ? values.email : "",
        Validators.compose([Validators.required, Validators.email])
      ),
      role_type_id: new UntypedFormControl(values ? this.getSysCapValue(values) : "", Validators.required),
      role: new UntypedFormControl(values ? this.getRoleTypeValue(values) : "", Validators.required),
    });

    if (this.showPersona) {
      newFormArray.addControl(
        "persona_id",
        new UntypedFormControl("", Validators.required)
      );
    }

    newFormArray.valueChanges.subscribe((value) => {
      this.isClean = false;
    });

    this.systemUsersFormArray.push(newFormArray);

    this.formLoading = false;
  }

  public getRoleTypeValue(values) {

    //defult role to last listed role from settings
    let thisRole = this.expRoleSelect[this.expRoleSelect.length - 1].value;

    if (values.event_role_id !== undefined) {
      let getRole = this.expRoleSelect.find((role) => +role.value === +values.event_role_id);
      if (getRole !== undefined) thisRole = getRole.value;
    } else if (values.event_role !== undefined) {
      let getRole = this.expRoleSelect.find((role) => role.name === values.event_role);
      if (getRole !== undefined) thisRole = getRole.value;
    } else if (values.meeting_role_id !== undefined) {
      let getRole = this.expRoleSelect.find((role) => +role.value === +values.meeting_role_id);
      if (getRole !== undefined) thisRole = getRole.value;
    } else if (values.meeting_role !== undefined) {
      let getRole = this.expRoleSelect.find((role) => role.name === values.meeting_role);
      if (getRole !== undefined) thisRole = getRole.value;
    }

    return thisRole;

  }

  public getSysCapValue(values) {
    let thisCap = this.adminCaps[this.adminCaps.length - 1].value;

    if (values.system_capability_id !== undefined) {
      let getCap = this.adminCaps.find((cap) => cap.value === +values.system_capability_id);
      if (getCap !== undefined) thisCap = getCap.value;
    } else if (values.system_capability !== undefined) {
      let getCap = this.adminCaps.find((cap) => cap.name === values.system_capability);
      if (getCap !== undefined) thisCap = getCap.value;
    }

    return thisCap;
  }

  public removeUserField(index) {
    this.systemUsersFormArray.removeAt(index);
  }

  public openUserModal(action, thisUser?): Promise<{ status: string; user: any; }> {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "delete-users-container"
    };

    this.modalOptions.data = {
      user: thisUser,
      users: [],
      targetUser: thisUser,
      teamID: this.teamID,
      action: action,
      domain: "",
      sourceType: "user",
    };

    this.restoreFrame = this.modalService.show(
      EditUserComponent,
      this.modalOptions
    );

    return new Promise((resolve, reject) => {
      this.restoreFrame.content.outgoing.subscribe((changedData) => {
        if (changedData.action !== undefined) {
          let actionAdj = action.replace(/e$/, "");
          this._notificationService.successNotification(
            `${this.TitleCase.transform(
              this.labels.user.singular
            )} successfully ${actionAdj}ed`
          );

          switch (changedData.action) {
            case "restore":
              if (changedData.user !== null) {
                let outgoingData = {
                  action: changedData.action,
                  user: changedData.user
                };

                this.outgoing.next(outgoingData);

                resolve({ status: "restored", user: changedData.user });
              } else {
                resolve({ status: "not_restored", user: null });
              }
              break;
            default:
              resolve({ status: "not_restored", user: null });
          }
        } else {
          resolve({ status: "not_restored", user: null });
        }
      });
    });
  }

  public async inviteUsers() {
    this.formState = "processing";

    this.notification = this._notificationService.savingNotification(
      `Adding ${this.labels.user.plural}`
    );

    let formValues = this.manageSystemUsersForm.value;

    console.log("formValues.users in inviteUsers", formValues.users);

    //for each user, update the role property to sit at meta.role
    for (let i = 0; i < formValues.users.length; i++) {
      formValues.users[i].meta = {
        role: formValues.users[i].role,
      };
      delete formValues.users[i].role;
    }

    //if we're showing personas, we need to add the persona_id to the meta object
    if (this.showPersona) {
      for (let i = 0; i < formValues.users.length; i++) {
        formValues.users[i].meta.persona_id = formValues.users[i].persona_id;
        delete formValues.users[i].persona_id;
      }
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let body = formValues.users;

    let submitUsers = await this.submitInvitees(body, options);
    let finalUsers = [];
    let outgoingData = {
      users: [],
      newGroup: null,
    };
    let errors = false;

    await Promise.all(
      submitUsers.users.map(async (user) => {

        if (user.error === false) {

          this._notificationService.successNotification(
            `${this.TitleCase.transform(
              this.labels.user.singular
            )} successfully added`, 3000
          );

          finalUsers.push(user);
          outgoingData.users.push(user);

          //remove from form
          let index = formValues.users.findIndex(
            (item) => item.username === user.username
          );
          this.systemUsersFormArray.removeAt(index);
          formValues.users.splice(index, 1);

        } else {

          this._notificationService.errorNotification(
            `Issue adding ${this.labels.user.singular}, please see errors below`
          );

          if (user.error_message === "username_exists_deleted" || user.error_message === "email_exists_deleted") {
            let handleRestore = await this.openUserModal("restore", user);

            console.log("handleRestore in inviteUsers", handleRestore);
            console.log("user in inviteUsers restored", user)

            if (handleRestore.status === "restored") {
              //remove from form
              let index = -1

              if (user.error_message === "username_exists_deleted") {
                index = formValues.users.findIndex(
                  (item) => item.username === user.username
                );
              } else if (user.error_message === "email_exists_deleted") {
                index = formValues.users.findIndex(
                  (item) => item.email === user.email
                );
              }

              this.systemUsersFormArray.removeAt(index);
              formValues.users.splice(index, 1);
            } else {

              console.log("user in inviteUsers not restored", user)

              errors = true;
              //mark form element with error
              let index = -1;

              if (user.error_message === "username_exists_deleted") {

                index = formValues.users.findIndex(
                  (item) => item.username === user.username
                );

                this.systemUsersFormArray.controls[index].get('username').setErrors({ 'username_exists': true });
              } else if (user.error_message === "email_exists_deleted") {

                index = formValues.users.findIndex(
                  (item) => item.email === user.email
                );

                this.systemUsersFormArray.controls[index].get('email').setErrors({ 'email_exists': true });
              }
            }


          } else if (user.error_message === "username_exists" || user.error_message === "email_exists") {

            errors = true;

            let index = -1;

            console.log("user in inviteUsers exists", user)
            console.log("this.index in exists", index);
            console.log("this.systemUsersFormArray exists", this.systemUsersFormArray.controls);

            if (user.error_message === "username_exists") {

              //mark form element with error
              index = formValues.users.findIndex(
                (item) => item.username === user.username
              );

              this.systemUsersFormArray.controls[index].get('username').setErrors({ 'username_exists': true });
            } else if (user.error_message === "email_exists") {

              console.log("formValues.users in inviteUsers", formValues.users)

              index = formValues.users.findIndex(
                (item) => item.email === user.email
              );

              console.log("index in inviteUsers", index);

              this.systemUsersFormArray.controls[index].get('email').setErrors({ 'email_exists': true });

            }
          } else {
            this._notificationService.errorNotification(
              `Error adding ${this.labels.user.singular}: ${user.error_message}`
            );
          }

        }

      })
    );

    this.handleGroups(outgoingData, finalUsers, options, errors);

  }

  private async handleGroups(outgoingData, outgoingUsers, options, errors) {
    let groupType = this.userGroupForm.get("group_type").value;

    if (groupType === "none") {
      this.finalActions(outgoingData, errors);
    } else if (groupType === "existing") {
      //we need to add these users to the included group
      let newMembers = outgoingUsers;
      let userGroup = this.userGroupForm.get("userGroup").value;

      let addGroupMembers = await this.addGroupMembers(
        options,
        newMembers,
        userGroup.id
      );

      this.finalActions(outgoingData, errors);
    } else if (groupType === "new") {
      let newMembers = outgoingUsers;
      let group_name = this.userGroupForm.get("group_name").value;
      let group_description =
        this.userGroupForm.get("group_description").value;

      let newGroup = await this.createGroup(
        options,
        group_name,
        group_description
      );

      let newGroupID = newGroup.group;

      let addGroupMembers = await this.addGroupMembers(
        options,
        newMembers,
        newGroupID
      );

      outgoingData.newGroup = {
        id: newGroupID,
        name: group_name,
        description: group_description,
        number_of_members: newMembers.length,
        number_of_events: 0,
      };
    }
  }

  private createGroup(options, group_name, group_description) {
    let createGroup = this._xrPlatformRestService.restfulAPIQuery(
      "/group",
      "post",
      {
        name: group_name,
        description: group_description,
        team_id: this.teamID,
        group_type: "user",
      },
      options
    );

    return createGroup.toPromise();
  }

  public async submitInvitees(body, options) {
    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      `/${this.teamID}/users/create`,
      "post",
      body,
      options
    );

    return eventRetrieve.toPromise();
  }

  private addGroupMembers(options, members, groupID) {
    let membersOutbound = this.buildMembers(members);

    console.log(
      "membersOutbound in AddUser::addGroupMembers()",
      membersOutbound
    );

    let addGroupMembers = this._xrPlatformRestService.restfulAPIQuery(
      "/group/" + groupID + "/members",
      "post",
      {
        members: membersOutbound,
      },
      options
    );

    return addGroupMembers.toPromise();
  }

  private buildMembers(incomingMembers) {
    let outgoingMembers = [];

    incomingMembers.forEach((element) => {
      outgoingMembers.push({
        name: element.username,
        reference_id: element.id,
        type: "user",
      });
    });

    return outgoingMembers;
  }

  private finalActions(data, errors) {
    this.formState = "success";

    let outgoingData = {
      action: "add",
      users: data.users,
      newGroup: data.newGroup,
    };

    this.outgoing.next(outgoingData);

    this._notificationService.clearNotification(this.notification);

    if (!errors) this.manageSystemUsersFrame.hide();
  }

  /**
   * Retrieve group information from groups array via group id
   * @param id
   *
   * @returns group object
   */
  public getGroup(id) {
    let group = this.userGroupSelect.filter((item) => item.id === id);

    return group[0];
  }

  public showingWarning() {
    this.manageSystemUsersForm.markAllAsTouched();
  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    if (!this.isClean && !overrule) return false;

    this.manageSystemUsersFrame.hide();
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }
}
