import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "./../../../../environments/environment.prod";

import { CoolLocalStorage } from "@angular-cool/storage";

import { ClientManagementService } from "./../../../services/utilities/client-management.service";
import { XrPlatformRestService } from "./../../../services/rest/xr-platform/xr-platform-rest.service";

@Injectable({
  providedIn: "root",
})
export class EventServicesService {
  private token: string;
  private restURL: string = this._clientManagementService.getRESTurl();

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private http: HttpClient,
    private _clientManagementService: ClientManagementService
  ) {}

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public async addInviteesToEvent(
    eventID,
    usersToAdd,
    isGroup?: boolean
  ): Promise<{ status: string; message: string; response: any }> {
    let resolution = await this.InviteesProcess(eventID, usersToAdd, isGroup);

    if (isGroup === undefined) isGroup = false;

    let outbound = {
      status: "success",
      message: resolution.message,
      response: resolution.response,
    };

    if (resolution.status === "error") {
      outbound.status = "error";
    }

    return Promise.resolve(outbound);
  }

  public async InviteesProcess(
    eventID,
    usersToAdd,
    isGroup,
    action?
  ): Promise<{ status: string; message: string; response: any }> {
    if (action === undefined) action === "add";

    let event = await this.retrieveEvent(eventID).catch ((error) => {
      return {
        status: "error",
        message: "Cannot retrieve event",
        response: error,
      };
    });

    ;
    ;
    ;

    let eventInvitees = event.scheduled_event.invitees;
    let finalInvitees = this.buildInvitees(
      usersToAdd,
      eventInvitees,
      eventID,
      action,
      isGroup
    );

    ;

    if (!finalInvitees.length)
      return {
        status: "success",
        message: "All invitees already added",
        response: event,
      };

    let result: any;
    let resultAction = "added to";

    if (action === "remove") {
      resultAction = "removed from";
      result = await this.removingInvitees(eventID, finalInvitees).catch (
        (error) => {
          return {
            status: "error",
            message: "Problem removing invitees",
            response: error,
          };
        }
      );
    } else {
      result = await this.addingInvitees(eventID, finalInvitees).catch (
        (error) => {
          return {
            status: "error",
            message: "Problem adding invitees",
            response: error,
          };
        }
      );
    }

    return {
      status: "success",
      message: `Invitees successfully ${resultAction} event`,
      response: result,
    };
  }

  public retrieveEvent(eventID): Promise<any> {
    this.retrieveToken();

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const httpOptions = {
      headers: headers,
    };

    let targetEvent = `${this.restURL}/schedule/${eventID}`;

    return this.http.get(targetEvent, httpOptions).toPromise();
  }

  public buildInvitees(
    inviteesToProcess,
    existingInvitees,
    eventID,
    action,
    isGroup
  ) {
    let finalInvitees = inviteesToProcess;
    let inviteesOut = [];

    if (action !== "remove") {
      //when adding new invitees, check to see if invitee is already added to this event (i.e. in the case we are editing an event)
      finalInvitees = inviteesToProcess.filter((userToAdd) => {
        return !existingInvitees.find((existingInvitee) => {
          return userToAdd.id === existingInvitee.id;
        });
      });
    }

    if (!finalInvitees.length) return inviteesOut;

    if (!isGroup) {
      finalInvitees.forEach((userToAdd) => {
        let thisInvitee = {
          invitee_type: "user",
          invitee_id: userToAdd.id,
          scheduled_event_id: eventID,
          //when removing, we don't need the meta prop
          ...(action !== "remove" && { meta: { individual: true } }),
        };

        inviteesOut.push(thisInvitee);
      });
    } else {
      let group_ids = [];

      finalInvitees.forEach((groupToAdd) => {
        group_ids.push(groupToAdd.id);
      });

      let thisInvitee = {
        invitee_type: "group",
        group_ids: group_ids,
        scheduled_event_id: eventID,
      };

      inviteesOut.push(thisInvitee);
    }

    return inviteesOut;
  }

  public addingInvitees(eventID, finalInvitees): Promise<any> {
    this.retrieveToken();

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const httpOptions = {
      headers: headers,
    };

    let targetURL = this.restURL + "/schedule/" + eventID + "/invitees";

    return this.http
      .post<any[]>(targetURL, finalInvitees, httpOptions)
      .toPromise();
  }

  public async removeInviteesFromEvent(
    eventID,
    inviteesToRemove,
    isGroup?: boolean
  ): Promise<{ status: string; message: string; response: any }> {
    console.log(
      "inviteesToRemove in EventService::removeInviteesFromEvent()",
      inviteesToRemove
    );

    if (isGroup === undefined) isGroup = false;

    //prep inviteesToRemove
    let preppedInviteesToRemove = [];

    inviteesToRemove.forEach((idToRemove) => {
      let thisRemove = {
        id: idToRemove,
      };

      preppedInviteesToRemove.push(thisRemove);
    });

    let resolution = await this.InviteesProcess(
      eventID,
      preppedInviteesToRemove,
      isGroup,
      "remove"
    );

    ;

    let outbound = {
      status: "success",
      message: resolution.message,
      response: resolution.response,
    };

    if (resolution.status === "error") {
      outbound.status = "error";
    }

    return Promise.resolve(outbound);
  }

  public removingInvitees(eventID, finalInvitees): Promise<any> {
    this.retrieveToken();

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const httpOptions = {
      headers: headers,
    };

    let targetURL = this.restURL + "/schedule/" + eventID + "/invitees";

    return this.http
      .put<any[]>(targetURL, finalInvitees, httpOptions)
      .toPromise();
  }
}
