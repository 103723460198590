<div class="modal-content-wrapper" (click)="modalClick($event)">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <span>Manage Hub for {{ this.currentExperience.label }}</span>
    </h4>
    <button
      *ngIf="isClean"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button
      *ngIf="!isClean"
      type="button"
      class="close cancel-alert"
      #popOverTriggerTop="bs-mdbPopover"
      id="cancelWarningCloseX"
      triggers="click"
      mdbPopoverHeader=""
      [mdbPopover]="cancelWarning"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div class="modal-body p-0 modal-fluid-content">
    <div class="hubs container-overflow-auto">
      <div class="w-100 row expereinces experiences-hub">
        <div class="col-md-12 hubs">
          <form *ngIf="!formLoading" [formGroup]="zoneHubsForm">
            <div
              class="zones-fields-wrapper"
              *ngFor="let zoneFields of zonesFieldsGroups; let i = index"
              [ngClass]="{ striped: i % 2 === 0 }"
            >
              <div class="zone-title">
                <p>Zone {{ i + 1 }}</p>
              </div>
              <div [formGroup]="zoneFields">
                <div class="grid">
                  <div class="md-form grid-item">
                    <input
                      type="text"
                      [id]="'label' + i"
                      class="form-control"
                      formControlName="zone_label"
                      mdbInput
                      placeholder="Enter Zone Name"
                    />
                    <label class="active-label active" [for]="'label' + i"
                      >Zone Name</label
                    >
                  </div>
                  <div class="md-form experience flex-grow-1">
                    <!--experience-->
                    <mdb-select-2
                      placeholder="Choose an {{
                        labels.experience.singular | titlecase
                      }}"
                      label="{{ labels.experience.singular | titlecase }} Name"
                      formControlName="zone_experience_id"
                    >
                      <mdb-select-option
                        *ngFor="let option of zonesSelect[i]"
                        [value]="option.value"
                        >{{ option.label }}</mdb-select-option
                      >
                    </mdb-select-2>
                  </div>
                </div>
                <div
                  mdbCollapse
                  [isCollapsed]="!zoneFields.controls['has_extras'].value"
                >
                  <div class="d-flex extras-actions">
                    <ng-template [ngIf]="getZoneExtrasFieldsGroups(i).length">
                      <ng-template
                        ngFor
                        let-extras_i="index"
                        let-zoneExtraFields
                        [ngForOf]="getZoneExtrasFieldsGroups(i)"
                      >
                        <div
                          class="extras-action extra"
                          [formGroup]="zoneExtraFields"
                        >
                          <ng-template
                            [ngIf]="
                              zoneExtraFields.controls.attribute_type.value ===
                              'asset_single'
                            "
                          >
                            <button
                              class="theme-modal-button btn"
                              type="button"
                              mdbBtnp
                              mdbWavesEffect
                              (click)="
                                openSelectMediaModal(
                                  extras_i,
                                  i,
                                  zoneExtraFields.controls.attribute_subtype
                                    .value,
                                  zoneExtraFields.controls.attribute_full_data
                                    .value
                                )
                              "
                            >
                              <fa-icon [icon]="faPlusSquare"></fa-icon>
                              Select
                              {{
                                zoneExtraFields.controls.attribute_label.value
                              }}
                            </button>
                            <div
                              *ngIf="
                                checkThumbnail(
                                  zoneExtraFields.controls.attribute_full_data
                                    .value
                                ) !== null
                              "
                              [ngStyle]="{
                                'background-image':
                                  'url(' +
                                  checkThumbnail(
                                    zoneExtraFields.controls.attribute_full_data
                                      .value
                                  ) +
                                  ')'
                              }"
                              class="thumbnail-container"
                            ></div>
                            <div
                              *ngIf="
                                checkThumbnail(
                                  zoneExtraFields.controls.attribute_full_data
                                    .value
                                ) === null
                              "
                              class="thumbnail-container fallback"
                            >
                              <fa-icon [icon]="faImages"></fa-icon>
                            </div>
                          </ng-template>
                        </div>
                      </ng-template>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="!formLoading"
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      [disabled]="isClean"
      (click)="updateZones()"
    >
      <span *ngIf="action === 'add'"
        >Add Zones and Return to {{ labels.event.singular }}</span
      >
      <span *ngIf="action === 'update'"
        >Update Zones and Return to {{ labels.event.singular }}</span
      >
    </button>
    <button
      *ngIf="isClean"
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="closeModal()"
    >
      Close
    </button>
    <button
      *ngIf="!isClean"
      mdbBtn
      mdbWavesEffect
      #popOverTrigger="bs-mdbPopover"
      id="cancelWarning"
      type="button"
      color="link"
      class="theme-modal-cancel-button btn cancel-alert"
      mdbPopoverHeader=""
      triggers="click"
      [mdbPopover]="cancelWarning"
    >
      Cancel
    </button>
  </div>
</div>

<ng-template #cancelWarning
  ><div class="custom-pop error-pop wider near-right">
    <div class="left-arrow"></div>
    <p class="semi-bold">
      Warning! All {{ action === "add" ? "work" : "updates" }} on this
      {{ labels.event.singular }} will be lost.
    </p>
    <div class="button-wrapper">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-button btn warning-stay"
        (click)="closePopOvers()"
      >
        Back to Edit {{ labels.event.singular }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn warning-leave"
        (click)="closeModal(true)"
      >
        Continue Cancel
      </button>
    </div>
  </div></ng-template
>
