import {
  Component,
  Input,
  OnInit,
  AfterViewChecked,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TitleCasePipe } from "@angular/common";

import { IMyOptions } from "ng-uikit-pro-standard";
import { ActivatedRoute } from "@angular/router";
import { MetricsServicesService } from "../services/metrics-services.service";
import zoomPlugin from "chartjs-plugin-zoom";
import * as moment from "moment";
import { DateTime, IANAZone } from "luxon";
import * as timezoneFixes from "src/assets/timezones/ianaToAbbreviation.json";
import "hammerjs";
import "chartjs-plugin-zoom";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
Chart.register(zoomPlugin);

import { CoolLocalStorage } from "@angular-cool/storage";

export class chartDates {
  constructor(public start: string, public end: string) { }
}

@Component({
  selector: "app-metrics-interactive",
  templateUrl: "./metrics-interactive.component.html",
  styleUrls: ["./metrics-interactive.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
})
export class MetricsInteractiveComponent implements OnInit, AfterViewChecked {
  //incoming
  @Input("teamID") teamID: number;
  @Input("clientCode") clientCode: string;

  //timezone
  public tz: any = moment.tz(moment.tz.guess()).format("z");

  //persistent
  public metricsLoading: boolean = true;
  public init: boolean = true;
  private baseURL: string;
  public labels;

  //table sorting
  public sortBy = "none";
  public sortOrder = "none";

  @ViewChild("sessionsChart") private chartRef: ElementRef;
  private chart: Chart;

  //form vars
  public metricsForm: UntypedFormGroup;

  //chart vars - sample data
  public chartLabel = "";
  public chartType: string = "horizontalBar";
  public chartDatasets: Array<any> = [
    {
      data: [],
      label: "",
      lineTension: 0,
    },
  ];
  public chartLabels: Array<any> = [];
  public chartColors: Array<any> = [
    {
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)"],
      borderWidth: 2,
    },
  ];
  public chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // public maxDate = moment();
  public model = new chartDates("", "");
  public startDateLabel: string = "Start Date";
  public endDateLable: string = "End Date";

  // charts options
  //chart vars - date selection chart
  public date = new Date();
  public disableDatesObj = { year: 0, month: 0, day: 0 };
  public disabledUntil = { year: 0, month: 0, day: 0 };
  public myDatePickerOptions: IMyOptions = {
    alignSelectorRight: true,
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableSince: this.disableDatesObj,
  };
  public endDateoickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableUntil: this.disabledUntil,
    disableSince: this.disableDatesObj,
  };

  public metricsLoadingDC: boolean = true;
  private chartCreated = false;
  private chartData: any;
  public dateAggregateTitle = "";
  public dateAggregates: {
    value: number;
    label: string;
  }[];
  public chartLabelDC = "";
  public chartLabelsDC: Array<any> = [];
  public chartOptionsDC: any = {
    indexAxis: "x",
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Total Connections",
        },
      },
      x: {
        title: {
          display: true,
          text: "Time Frame",
        },
      },
    },
    interaction: {
      mode: "point",
    },
    //onClick: function adjustLineEndPoints(context) {  }
  };
  public timeDenomination = [
    { value: "hour", label: "Hourly" },
    { value: "day", label: "Daily" },
    { value: "month", label: "Monthly" },
    { value: "year", label: "Yearly" },
  ];

  public timeSelector: UntypedFormControl = new UntypedFormControl("day");

  //chart vars - line data test
  public chartTypeLT: string = "bar";
  public chartDatasetsLT: Array<any> = [];
  public chartLabelsLT: Array<any> = [];

  public yLabels: Array<string>;

  public chartOptionsLT: any = {};
  public chartColorsLT: Array<any> = [
    {
      backgroundColor: ["#7F95D1"],
      borderColor: ["#7F95D1"],
    },
  ];

  public lineBreakLoading = false;
  public lineBreakSelected = false;

  public basicLoad = false;

  public sessions: any[] = [];
  public sessionsLoaded: boolean = false;
  headElements = [];
  sort_elements = [
    "session_name",
    "distinct_users",
    "total_connections",
    "start_time",
    "end_time",
    "duration",
  ];

  public start;
  public end;
  public breakdownType;

  searchText: string = "";
  previous: string;

  public currentSort = "";
  public ascSort = false;

  constructor(
    private route: ActivatedRoute,
    private _metricsServicesService: MetricsServicesService,
    private coolLocalStorage: CoolLocalStorage,
    private TitleCase: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.retrieveLabels();

    this.headElements = [
    `${this.TitleCase.transform(this.labels.event.singular)} Name`,
    "Distinct Users",
    "Total Entries",
    "Start Time",
    "End Time",
    "Duration",
  
    ];

    this.resolveTimeZone();

    //labels in headElements
    this.headElements[0] = `${this.TitleCase.transform(
      this.labels.event.singular
    )} Name`;
    this.headElements[1] = `Distinct ${this.TitleCase.transform(
      this.labels.user.plural
    )}`;
    this.headElements[3] = `${this.TitleCase.transform(
      this.labels.startTime.singular
    )}`;
    this.headElements[4] = `${this.TitleCase.transform(
      this.labels.endTime.singular
    )}`;

    this.startDateLabel = this.TitleCase.transform(
      this.labels.startDate.singular
    );
    this.endDateLable = this.TitleCase.transform(this.labels.endDate.singular);

    this.lineBreakLoading = true;
    //this.setupUserBreakdown()

    this.route.queryParams.subscribe((params) => {

      console.log("params", params);

      let start_date = params["start_date"];
      let end_date = params["end_date"];
      let breakdownType = params["breakdownType"];
      if (breakdownType != undefined) {
        this.timeSelector = new UntypedFormControl(breakdownType);
      }
      if (start_date != undefined && end_date != undefined) {
        this.model = new chartDates(
          moment(start_date).format("MMM D, YYYY"),
          moment(end_date).format("MMM D, YYYY")
        );
        this.setupDateChart();
        this.disabledDates(moment());
        this.generateUntilDate();
      } else {
        // const date = new Date()
        const today = moment();
        // called moment funct because the local var gets reasigned
        this.model = new chartDates(
          today.subtract(7, "d").format("MMM D, YYYY"),
          moment().format("MMM D, YYYY")
        );
        this.setupDateChart();
        this.disabledDates(moment());
        this.generateUntilDate();
      }
    });
  }

  ngAfterViewChecked() {
    if (!this.chartCreated && this.chartRef && this.chartRef.nativeElement && !this.metricsLoadingDC) {
      this.chartCreated = true;

      console.log("creating chart");

      this.chart = new Chart(this.chartRef.nativeElement, {
        type: "bar",
        data: this.chartData,
        options: this.chartOptionsDC,
      });
    }
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  public setupDateChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    let start = this.model.start;
    let end = this.model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");
    let tmz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.start = startDate;
    this.end = endDate;
    this.breakdownType = this.timeSelector.value;

    let retrieveMetricsData =
      this._metricsServicesService.getMetricsOverTimeFrame(
        this.teamID,
        this.timeSelector.value,
        startDate,
        endDate,
        tmz
      );

    retrieveMetricsData.subscribe(
      (response: any) => {
        let graph = response.Graph;
        let self = this;

        this.chartLabelDC = this.processChartLabel(graph.graphTitle);
        this.chartLabelsDC = graph.labelData;

        console.log("graph.Data", graph.Data);
        console.log("chartLabelsDC", this.chartLabelsDC);

        this.dateAggregateTitle = response.Title;

        let aggregates = [];
        Object.keys(response.Aggregates[0]).forEach(function (key) {
          let thisAggregate = {
            label: key,
            value: response.Aggregates[0][key],
          };

          aggregates.push(thisAggregate);
        });

        this.dateAggregates = aggregates;

        let xmin = moment(`${startDate} 00:00 AM`).format("X");
        let xmax = moment(`${endDate} 11:59 PM`).format("X");

        console.log("xmin pre selected", xmin);
        console.log("xmax pre selected", xmax);

        if (this.timeSelector.value === "hour") {
          //xmin = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
          //xmax = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
        } else if (this.timeSelector.value === "month") {
          xmin = moment(startDate).format("YYYY-MM-01");
          xmax = moment(endDate).endOf('month').format("YYYY-MM-DD");
        } else if (this.timeSelector.value === "year") {
          xmin = moment(startDate).format("YYYY-01-01");
          xmax = moment(endDate).format("YYYY-12-31");
        }

        console.log("xmin", xmin);
        console.log("xmax", xmax);

        const zoomOptions = {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
        };

        let unit = this.timeSelector.value === "hour" ? "minute" : this.timeSelector.value;

        console.log("unit", unit);

        let defaultYLabels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        let ymax = graph.Data.length ? Math.max(...graph.Data) : Math.max(...defaultYLabels);
        let yLabels = [...Array(ymax).keys()].push(ymax);

        this.chartOptionsDC = {
          indexAxis: "x",
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: "rgba(29; 61; 124; 0.65)",
          plugins: {
            // zoom: zoomOptions,
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  let format = "LLL d, yyyy";

                  console.log("self.timeSelector.value", self.timeSelector.value);
                  console.log("context in tooltip", context);

                  if (self.timeSelector.value === "hour") {
                    format = "LLL d, yyyy h:mm a";
                  } else if (self.timeSelector.value === "month") {
                    format = "LLL yyyy";
                  } else if (self.timeSelector.value === "year") {
                    format = "yyyy";
                  }

                  let date = DateTime.fromMillis(context[0].parsed.x, { zone: IANAZone.create(tmz) });

                  let label = date.toFormat(format);
                  return label;
                },
                label: function (context) {
                  return `${context.formattedValue} sessions`;
                }
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Number of Sessions",
              },
              min: 0,
              max: ymax + 1
            },
            x: {
              title: {
                display: true,
                text: "Date",
              },
              type: 'time',
              min: xmin,
              max: xmax,
              time: {
                parser: moment.parseZone,// Your current date format
                tooltipFormat: 'll',
                unit: unit
              },
              ticks: {
                callback: function (value, index, values) {
                  let date = DateTime.fromMillis(values[index].value, { zone: IANAZone.create(tmz) });

                  console.log("date in ticks", date);

                  if (date.isValid) {

                    let format = "LLL d, yyyy";

                    if (self.timeSelector.value === "hour") {
                      format = "LLL d, yyyy h:mm a";
                    } else if (self.timeSelector.value === "month") {
                      format = "LLL yyyy";
                    } else if (self.timeSelector.value === "year") {
                      format = "yyyy";
                    }

                    console.log("date.format(format) in ticks", date.toFormat(format));

                    return date.toFormat(format);
                  } else {

                    console.log("value in ticks", value);

                    return value;
                  }
                }
              }
            },
          },
          interaction: {
            mode: "point",
          },
          //onClick: function adjustLineEndPoints(context) {  }
        };

        let data = {
          labels: graph.labelData,
          datasets: [
            {
              data: graph.Data,
              borderColor: "#483c46",
              maxBarThickness: 50,
              backgroundColor: [
                'rgba(0, 35, 56, 0.42)',
                'rgba(72, 60, 70, 0.42)',
                'rgba(75, 45, 27, 0.42)',
                'rgba(4, 201, 209, 0.42)',
                'rgba(38, 131, 140, 0.42)',
                'rgba(190, 124, 77, 0.42)',
                'rgba(160, 142, 106, 0.42)'
              ],
              label: "",
            },
          ],
          yLabels: yLabels,
        };

        if (this.timeSelector.value === "hour") {
          data.datasets[0]["barThickness"] = 3;
        }

        this.sessions = [];
        this.getSessions();

        this.chartData = data;
        this.metricsLoadingDC = false;
        //first chart initialization is in ngAfterViewChecked

        if (this.chartCreated) {
          this.chart = new Chart(this.chartRef.nativeElement, {
            type: "bar",
            data: this.chartData,
            options: this.chartOptionsDC,
          });
        }


      },
      (error) => { }
    );
  }

  private processChartLabel(labelIn) {
    labelIn = labelIn.replace(
      "Meeting",
      this.TitleCase.transform(this.labels.event.singular)
    );

    labelIn = labelIn.replace("meeting", this.labels.event.singular);

    return labelIn;
  }

  public getSessions() {
    let tmz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let start = this.model.start;
    let end = this.model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");

    let retrieveMetricsData = this._metricsServicesService.getSessions(
      this.teamID,
      startDate,
      endDate,
      tmz
    );

    retrieveMetricsData.subscribe(
      (response: any) => {
        this.sessions = response.sessions.map((session) => {

          let start_time = DateTime.fromFormat(session.start_time.replace(this.tz, tmz), 'LLLL dd yyyy, h:mm a z', { setZone: true });
          let end_time = DateTime.fromFormat(session.end_time.replace(this.tz, tmz), 'LLLL dd yyyy, h:mm a z', { setZone: true });

          session.start_time = start_time.toISO();
          session.end_time = end_time.toISO();

          return session;

        });
        this.sessions = this.sessions.sort((a, b) => {
          return b.session_id - a.session_id;
        });
        console.log("this.sessions in getSessions", this.sessions);
        this.sessionsLoaded = true;
      },
      (error) => { }
    );
  }

  private resolveTimeZone() {
    let tz_iana = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.tz = moment.tz(tz_iana).format("z");

    //if the string includes a negative number in the form -03 or -02, check for a timezone fix
    if (this.tz.includes("-")) {
      let fix = timezoneFixes[tz_iana];

      if (fix !== undefined) {
        this.tz = fix;
      } else {
        //fall back to UTC from Luxon
        this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
      }
    } else if (this.tz.includes("+")) {
      //fall back to UTC from Luxon
      //@todo: update timezone fixes to include plus returns
      this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
    }
  }

  public checkDateRange(event, dateField) {

    let startDate = typeof this.model.start === "string" ? moment(this.model.start, "MMM D, YYYY") : moment(this.model.start);
    let endDate = typeof this.model.end === "string" ? moment(this.model.end, "MMM D, YYYY") : moment(this.model.end);

    if (dateField === 'start') {
      startDate = moment(event.actualDateFormatted);
    } else {
      endDate = moment(event.actualDateFormatted);
    }

    //see if diff between dates is greater than 7 days
    let diff = endDate.diff(startDate, "days");

    console.log("diff", diff);

    //if greater than 7 days, set "hour" option in timeSelector to disabled
    this.timeDenomination = this.timeDenomination.map((item) => {
      if (diff > 7 && item.value === "hour") {
        item["disabled"] = true;
      } else {
        item["disabled"] = false;
      }

      return item;
    });
  }

  /**
   * Custom table sorting based on current head key
   * @param head
   * @param direction
   */
  public sortTable(head, direction) {
    this.sortBy = head;
    this.sortOrder = direction;

    switch (head) {
      case "session_name":
        if (direction === "desc") {
          this.sessions = this.sessions.sort((a, b) => {
            return a[head].localeCompare(b[head]);
          });
        } else {
          this.sessions = this.sessions.sort((a, b) => {
            return b[head].localeCompare(a[head]);
          });
        }

        break;
      case "start_time":
      case "end_time":
        let tmz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let endParsed =
          moment(this.model.end).format("MMMM DD YYYY") + " 11:59 PM " + tmz;
        let endDateTime = DateTime.fromFormat(endParsed, 'LLLL dd yyyy h:mm a z', { setZone: true });
        let end = endDateTime.toISO();
        if (direction === "desc") {
          this.sessions = this.sessions.sort((a, b) => {
            if (a[head] === null || a[head].toLowerCase().indexOf("in progress") !== -1)
              a[head] = end;
            if (b[head] === null || b[head].toLowerCase().indexOf("in progress") !== -1)
              b[head] = end;

            let dateA = moment(a[head]).format("X");
            let dateB = moment(b[head]).format("X");

            return parseInt(dateB) - parseInt(dateA);
          });
        } else {
          this.sessions = this.sessions.sort((a, b) => {
            if (a[head] === null || a[head].toLowerCase().indexOf("in progress") !== -1)
              a[head] = end;
            if (b[head] === null || b[head].toLowerCase().indexOf("in progress") !== -1)
              b[head] = end;

            let dateA = moment(a[head]).format("X");
            let dateB = moment(b[head]).format("X");

            return parseInt(dateA) - parseInt(dateB);
          });
        }

        break;
      case "duration":
        if (direction === "desc") {
          this.sessions = this.sessions.sort((a, b) => {
            return (
              this.convertDuration(b[head]) - this.convertDuration(a[head])
            );
          });
        } else {
          this.sessions = this.sessions.sort((a, b) => {
            return (
              this.convertDuration(a[head]) - this.convertDuration(b[head])
            );
          });
        }

        break;
      default:
        if (direction === "desc") {
          this.sessions = this.sessions.sort((a, b) => {
            return b[head] - a[head];
          });
        } else {
          this.sessions = this.sessions.sort((a, b) => {
            return a[head] - b[head];
          });
        }
    }
  }

  private convertDuration(incoming: string) {
    let hoursCalc = 0;
    let minutesCalc = 0;

    if (incoming.toLowerCase() === "in progress") return -1;

    if (incoming.indexOf("h") !== -1) {
      let getHours = incoming.split("h");
      hoursCalc = parseInt(getHours[0]) * 60;
      incoming = getHours[1];
    }

    if (incoming.indexOf("m") !== -1) {
      let getMinutes = incoming.split("m");
      minutesCalc = parseInt(getMinutes[0]);
    }

    return hoursCalc + minutesCalc;
  }

  public chartClicked(e: any): void {
    if (e.active[0].element.parsed.x) {
    }
  }
  public chartHovered(e: any): void { }

  public disabledDates(currentDate: any) {
    let today = currentDate;
    let endDays = [28, 29, 30, 31];
    // handle year
    if (today.month() === 11 && today.date() === 31) {
      this.disableDatesObj.year = today.year() + 1;
    } else {
      this.disableDatesObj.year = today.year();
    }
    // handle month
    // handle day
    if (endDays.every((day) => day === today.date())) {
      // I need to find a way to test this
      this.disableDatesObj.day = 1;
      this.disableDatesObj.month = today.month() + 2;
    } else {
      this.disableDatesObj.day = today.date() + 1;
      this.disableDatesObj.month = today.month() + 1;
    }
  }

  // todo: edgecases for first dat of the month, and or last day of the month.
  public generateUntilDate() {
    // let today = currentDate;
    let untilDate = this.model.start;
    this.disabledUntil.year = parseInt(moment(untilDate).format("YYYY"));
    this.disabledUntil.day = parseInt(moment(untilDate).format("DD")) - 1;
    this.disabledUntil.month = parseInt(moment(untilDate).format("MM"));
  }
}
